import React, {useState} from 'react'
import style from './ModalChar.module.css'
import avatar from './img/Group 604.png'
import SendMessige from "./SendMessige";

export default function ModalChat() {

    const [messages, setMessiges] = useState([])

    return (<div className={style.block}>
        <div className={style.header}>
            <img src={avatar} alt=""/>
            <div className={style.info}>
                <h3>Андрей</h3>
                <h4>Старший чат-бот</h4>
            </div>
        </div>
        <div className={style.chatBlock}>
            <div className={style.fullMssiges}>
                {messages.map(e => {
                    return e
                })}
            </div>

        </div>
        <SendMessige setMessiges={setMessiges} messages={messages}/>
    </div>)
}
