import React from 'react'
import {Route, Routes} from "react-router-dom";
import HomePage from "./homePage/HomePage";
import Events from "./events/Events";
import CardPage from "./CardPage/CardPage";
import date from './events/eventsList/index'
import BlockItam from "./blogPage/blogItam/BlockItam";
import Management from "./Management/Management";
import Blog from "./blogPage/Blog";
import ArticlesPage from "./Articlespage/ArticlesPage";

export default function Routing() {

    return (

        <Routes>

            <Route path={'/'} element={<HomePage/>}/>
            <Route path={'/blog'} element={<Blog/>}/>
            <Route path={'/arcticles'} element={<ArticlesPage/>}/>
            <Route path={'/events'} element={<Events/>}/>
            {date.map(e => {
                return <Route key={`route${e.id}`} path={`/event${e.id}`} element={<CardPage props={e}/>}/>

            })}
            <Route path={'/Blosgid632165'} element={<BlockItam/>}/>
            <Route path={'/admin'} element={<Management/>}/>

        </Routes>)
}

