import React from 'react'
import Initro from "./initro/Initro";
import Explanation from "./explanation/Explanation";
import Utility from "./Utility/Utility";
import Prototyping from "./protoTyping/Prototyping";
import Controlinterface from "./controlInterface/Controlinterface";
import Workingwithdata from "./WorkingWithData/Workingwithdata";
import Case from "./case/Case.jsx";
import HomepageFoother from "./FootherHomepage/HomepageFoother";
import Header from "./header/Header";

export default function HomePage() {
    return (
        <div className={'container'}>
            <div className="header">
                <Header props={''}/>
            </div>
            <Initro/>
            <Explanation/>
            <Utility/>
            <Prototyping/>
            <Controlinterface/>
            <Workingwithdata/>
            <Case/>
            <HomepageFoother/>
        </div>
    )
}
