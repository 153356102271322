import React from 'react'
import HeaderBlog from "./header/HeaderBlog";
import style from './BlockItam.module.css'
import {Link} from "react-router-dom";
import img1 from './img/image 82 (7).png'
import svgimg from './img/Vector 142.png'
import avatar from './img/Ellipse 40.png'
import iconsvg from "../../Articlespage/img/Vector 5 (7).png";
import footherimg1 from "../../Articlespage/img/Rectangle 5.png";
import footherimg2 from "../../Articlespage/img/Vector 17.png";
import img2 from './img/Rectangle 57.png'

export default function BlockItam() {
    return (<div className={style.all}>

        <div className="header">
            <HeaderBlog/>
        </div>
        <div className={style.block}>
            <div className={style.bannerBlock}>

                <div className={style.leftBlock}>
                    <div className={style.links}>
                        <Link>Главная</Link>
                        <img width={'8px'} height={'8px'} src={svgimg} alt=""/>
                        <Link>Блог</Link>
                        <img width={'8px'} height={'8px'} src={svgimg} alt=""/>
                        <Link>Статьи</Link>
                    </div>
                    <h2>Особенности разработки дизайна дашборда для b2b-компании</h2>
                    <div className={style.redLine}>
                        <p>В статье мы разберем ключевые рекомендации по созданию дизайна B2B-сайтов. С ними вы
                            сможете ускорить принятие решений потенциальными клиентами (пользователями) на сайте и
                            повысите уровень доверия к компании</p>
                    </div>
                    <div className={style.contactBlock}>
                        <img width={'56px'} height={'56px'} src={avatar} alt=""/>
                        <div>
                            <h3>Денис Бабенко</h3>
                            <p>Senior BI-developer в Win Solutions. Опыт проектов в международных компаниях Omega-R
                                (агентство), iSpring Solutions (продуктовая) и со стартапами по всему миру</p>
                        </div>
                    </div>
                </div>
                <div className={style.rightBlock}>
                    <img width={'100%'} src={img1} alt=""/>
                </div>

            </div>

            <div className={style.itamConTexts}>
                <h2>Бюджеты и ценообразование в B2B</h2>
                <p>B2B-клиентов беспокоит вопрос цены, так же как и в B2С. Однако работать с бюджетами в B2B может
                    быть сложнее, чем с личными бюджетами, потому что они:</p>
                <ul>

                    <li>зачастую задаются менеджером или другими представителями из управляющего звена. Бюджет
                        зачастую задает не тот, кто зашел к вам на сайт с целью исследовать и купить продукт;
                    </li>
                    <li>согласуются заранее и с трудом изменяются в течение года;</li>
                    <li>требуют нескольких уровней проработки: включают такие элементы, как долгосрочная поддержка
                        или техническое обслуживание наряду с покупкой
                    </li>
                </ul>
                <p>Представьте себе следующую ситуацию: B2B-клиент сделал исследование и получил одобрение бюджета,
                    но после разговора с отделом продаж решил, что другое, более дорогое решение будет лучше
                    изначального. На такое дело у него могут отсутствовать полномочия увеличивать бюджет, или для
                    этого может потребоваться, чтобы закрылся финансовый год. Из-за этих сложных бюджетных
                    ограничений. Т.е B2B-клиентам важно знать, что их решение о покупке не приведет к непредвиденным
                    расходам где-то в будущем.</p>
                <p>Поэтому вам следует ясно демонстрировать цены для B2B-сегмента, чтобы пользователи ощущали
                    прозрачность ваших действий. Фактически, первый сайт, на который они зайдут из поиска покажет им
                    цену, которая уже может <Link>сформировать</Link> определенные ожидания.</p>
                <p>Некоторые B2B-сайты всячески стремятся избегать ложных ожиданий и скрывают все цены, тем самым
                    побуждая пользователей "запросить цену" или "связаться с отделом продаж", чтобы их посмотреть —
                    им кажется, что такой подход хорош для вывода клиентов на контакт. Однако, такая стратегия
                    сокрытия цен отпугивает пользователей, потому что:</p>
                <img src={img2} alt=""/>
                <h2>Рекомендации</h2>
                <h3>1. Показывайте цену и если цена переменная, предложите общие сценарии ценообразования.</h3>
                <p>Возможно, цена варьируется по таким причинам, как комплектация или в связи с необходимостью серии
                    переговоров в рамках контракта. Чтобы дать представление о цене, не устанавливая
                    нереалистических ожиданий, покажите распространенные сценарии ценообразования. Например,
                    отображать начальную цену (которую можно повысить в зависимости от требований) или диапазон
                    цен.</p>
                <h3>2. Объясняйте модель ценообразования: планы, пакеты или уровни обслуживания.</h3>
                <p>Учтите принцип неприятия потерь, показав пользователям, какие дополнительные функции входят в
                    более дорогие пакеты. Такой подход побуждает людей приобретать немного более дорогой пакет, если
                    у них есть место для маневра в бюджете.</p>
            </div>

        </div>
        <div className={style.foother}>
            <ul>
                <Link>Обзор</Link>
                <Link>Возможности <img src={iconsvg} alt=""/> </Link>
                <Link>Документация</Link>
                <Link>Демо-версия</Link>
                <Link>Блог</Link>
                <Link>Купить</Link>
            </ul>
            <p>
                © 2023 Fastboard
                <span>+7 (988) 456-78-99</span>
            </p>
            <div>
                <img className={style.footherimg1} src={footherimg1} alt=""/>
                <img className={style.footherimg2} src={footherimg2} alt=""/>
            </div>
        </div>
    </div>)
}
