import React from 'react'
import style from "../protoTyping/Prototyping.module.css";

export default function Backgraundgrey() {
    return (<div className={style.backgraundFone}>
        <div className={style.layer1}></div>
        <div className={style.layer2}></div>
        <div className={style.layer3}></div>
    </div>)
}
