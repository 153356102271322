import React, {useState} from 'react';
import style from "./ModalChar.module.css";

const MyComponent = ({setMessiges, messages}) => {
    const [state, setState] = useState('')
    const [emojiOn, setEmojiOn] = useState('none')
    const emoji = Object.entries(require("emojilib"))
    const emojiBlock = <div className={style.emoji} id={'openEmoji'}>
        {emoji.map((e, i) => i < 100 && i > 0 ?
            <p key={i} onClick={() => setState(state + emoji[i][0])}>{e[0]}</p> : '')}
    </div>
    const usermessage = <div className={style.newMessage}>
        <p>{state} </p>
        <img width={'25px'} src="https://cdn-icons-png.flaticon.com/512/9187/9187604.png" alt=""/>
    </div>

    function handleSubmit(e) {
        e.preventDefault();
        setMessiges([...messages, usermessage])
        setState('')
    }

    return (<>
        <div style={{display: emojiOn}}>
            {emojiBlock}
        </div>

        <div className={style.message}>
            <form onSubmit={handleSubmit}>
                <input className={style.messageInput} placeholder={'введите сообщение'} type="text" value={state}
                       onChange={(e) => setState(e.target.value)}/>
                <button type={"submit"} className={style.sendMessige}>&uarr;</button>
            </form>
            <p onClick={() => setEmojiOn(emojiOn === 'none' ? 'block' : 'none')} style={{fontSize: '35px', cursor : "pointer" , width: '35px'}}>
                {emoji[18][0]}
            </p>
        </div>
    </>)
};

export default MyComponent;
