import './App.css';
import Routing from "./components/Routing";

function App() {
    return (<div className="App">
        <div className={'container  cont'}>
            <Routing/>
        </div>

    </div>);
}

export default App;
