import img1 from './img/image 82 (1).png'
import img2 from './img/image 82.png'
import img3 from './img/image 82 (2).png'
import img4 from './img/image 82 (3).png'
import img5 from './img/image 82 (4).png'
import img6 from './img/image 82 (5).png'
import img7 from './img/image 82 (6).png'

const date = [
    {
        id: 1,
        img: img1,
        title: 'Особенности разработки дизайна дашборда для b2b-компании',
        cotegory: 'Разработка',
        thime: '5 мин'
    },
    {
        id: 2,
        img: img2,
        title: 'Особенности разработки дизайна дашборда для b2b-компании',
        cotegory: 'Разработка',
        thime: '5 мин'
    },
    {
        id: 3,
        img: img3,
        title: 'Будущее уже наступило — 3-е поколение BI и аналитики как раз вовремя',
        cotegory: 'Разработка',
        thime: '5 мин'
    },
    {
        id: 4,
        img: img4,
        title: 'Будущее уже наступило — 3-е поколение BI и аналитики как раз вовремя',
        cotegory: 'Разработка',
        thime: '5 мин'
    },
    {
        id: 5,
        img: img5,
        title: 'Attunity и Fastboard: поддержка DataOps (часть 2)',
        cotegory: 'Разработка',
        thime: '5 мин'
    },
    {
        id: 6,
        img: img6,
        title: 'Особенности разработки дизайна дашборда для b2b-компании',
        cotegory: 'Разработка',
        thime: '5 мин'
    },
    {
        id: 7,
        img: img7,
        title: 'Будущее уже наступило — 3-е поколение BI и аналитики как раз вовремя',
        cotegory: 'Разработка',
        thime: '5 мин'
    },
]

export default date